import React from 'react';

import './styles.css';

export default function CardState({ title, cases, deaths }) {
  return (
    <li>
      <strong>{title}</strong>

      <div className="stateItem">
        <div>
          <p className="orange"></p>
          <p className="red"></p>
        </div>
        <div className="stateInfo">
          <span>{cases} Confirmados</span>
          <span>{deaths} Mortes</span>
        </div>
      </div>
    </li>
  );
}
