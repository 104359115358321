import React from 'react';

import './styles.css';
import imgLoading from '../../assets/loading.svg';

export default function CardInfo({ title, info, color, loading }) {
  let classCss = `cardInfo ${color}`;

  return (
    <div className={classCss}>
      {loading ? (
        <img src={imgLoading} width="30" height="30" />
      ) : (
        <span className="number">{info}</span>
      )}
      <span>{title}</span>
    </div>
  );
}
