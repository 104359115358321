import React, { useEffect, useState } from 'react';
import moment from 'moment';

import './styles.css';
import imgLoading from '../../assets/loading.svg';

import CardInfo from '../../components/CardInfo';
import CardState from '../../components/CardState';

import api from '../../services/api';

export default function Home() {
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const [states, setStates] = useState([]);
  const [loadingStates, setLoadingStates] = useState(false);

  useEffect(() => {
    loadData();
    loadStates();
  }, []);

  async function loadData() {
    setLoading(true);

    try {
      const response = await api.get('/brazil');
      setInfo(response.data.data);
    } catch (err) {
      alert('Erro ao trazer os dados, tente novamente!');
    }

    setLoading(false);
  }

  async function loadStates() {
    setLoadingStates(true);
    try {
      const response = await api.get('/');
      setStates(response.data.data);
    } catch (err) {
      alert('Erro ao trazer os dados, tente novamente!');
    }

    setLoadingStates(false);
  }

  return (
    <>
      <header>
        <h1>Casos no Brasil</h1>
      </header>

      <div className="container">
        <div className="cardContainer">
          <CardInfo
            title="Confirmados"
            info={info.confirmed}
            color="orange"
            loading={loading}
          />

          <CardInfo
            title="Mortes"
            info={info.deaths}
            color="red"
            loading={loading}
          />

          <CardInfo
            title="Recuperados"
            info={info.recovered}
            color="green"
            loading={loading}
          />
        </div>

        <span className="updateText">
          Atualizado: {moment(info.updated_at).format('DD/MM/YYYY HH:mm:ss')}
        </span>

        {loadingStates && <img src={imgLoading} width="100" height="100" />}

        <ul>
          {states.map((item) => (
            <CardState
              key={item.state}
              title={item.state}
              cases={item.cases}
              deaths={item.deaths}
            />
          ))}
        </ul>
      </div>
      <a
        href="https://www.brcintra.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <footer>
          <span>Desenvolvido com ReactJS por StewartCintra 2020</span>
        </footer>
      </a>
    </>
  );
}
